import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import BlogSlider from 'components/blogs/blog-slider'
import ButtonBlack from 'components/button/button-black'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const ContactPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Contact Us - The Dentists At Gateway Crossing</title>
      <meta name="description" content="office@thedentistsatgc.com" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Contact - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="office@thedentistsatgc.com" />
      <meta property="og:url" content="https://thedentistsatgc.com/contact/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Contact - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="office@thedentistsatgc.com" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg contact-us-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateway Crossing"
      >
        <div className ="col-lg-12" id ="color-overlay-dark"></div>
        <div id="hero-section" className="text-center"></div>
      </BackgroundImage>
      <section id="contact-section-1" className="page-section">
        <Container>
          <div className="col-lg-12">
            <h2 className="text-center m-b-20">Contact our team today</h2>
          </div>
          <div className="col-lg-6">
            <div className="contact-location-container">
              <h4>LOCATION</h4>
              <div className="map-frame">
                <iframe width="100%" height="300px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=the%20dentists%20at%20gateway%20crossing&t=m&z=10&output=embed&iwloc=near"></iframe>
              </div>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded btn-solid bg-main m-t-35"> Book Online </button></a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-location-container">
              <h4>OUR CONTACT</h4>
              <hr className="text-black" />
              <div className="row">
                <div className="col-lg-2">
                  <a href="mailto:office@thedentistsatgc.com"><i className="fa fa-envelope"></i></a>
                </div>
                <div className="col-lg-10">
                  <a href="mailto:office@thedentistsatgc.com"><p className="text-primary">Email Address</p></a>
                  <p>office@thedentistsatgc.com</p>
                </div>
              </div>
              <hr className="text-black" />
              <div className="row">
                <div className="col-lg-2">
                  <a href="tel:317-643-9434"><i className="fa fa-phone"></i></a>
                </div>
                <div className="col-lg-10">
                  <a href="tel:317-643-9434"><p className="text-primary">Call Us</p></a>
                  <p>317-643-9434</p>
                </div>
              </div>
              <hr className="text-black" />
              <div className="row">
                <div className="col-lg-2">
                  <i className="fa fa-home text-white"></i>
                </div>
                <div className="col-lg-10">
                  <p className="text-primary">Our Address</p>
                  <p>6621 W Broadway STE 600, McCordsville, IN 46055</p>
                </div>
              </div>
              <hr className="text-black" />
              <h4>SOCIAL MEDIA</h4>
              <hr className="text-black" />
              <div className="contact-social-medias">
                <a href="http://www.facebook.com/thedentistsatgc"><i className="fa fa-facebook text-primary"></i></a>
                <a href="http://instagram.com/thedentistsatgc"><i className="fa fa-instagram text-primary"></i></a>
                <a href="https://twitter.com/thedentistsatgc"><i className="fa fa-twitter text-primary"></i></a>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "mccordsville-dental-office" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
